.guest-layout {
    display: flex;

    .image {
        flex: 1 0 0%;

        .img {
            max-width: 100%;
            height: 100vh;
            background-image: url('../../assets/images/besillio-grand.png');
        }
    }

    .form {
        flex: 1 0 0%;
        margin: auto 0;
        padding: 0 20px;

        .card {
            box-shadow: 0 0 10px var(--main);
            max-width: 400px;
            width: 100%;
            margin: 0 auto;
            border-radius: 0;

            .logo {
                max-width: 80px;
            }
        }

    }
}