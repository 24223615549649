.wrapper {
    padding-top: 80px;
    transition: all 0.4s;
    position: relative;
    min-height: calc(100vh - 40px);

    header {
        background-color: var(--main);
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 999;

        .logo {
            max-width: 48.5px;
        }

        .toggler {
            position: relative;

            a {
                &.show {
                    position: relative;

                    &::before {
                        content: "";
                        width: 0;
                        height: 0;
                        border-left: 12px solid transparent;
                        border-right: 12px solid transparent;
                        border-bottom: 18px solid var(--light);
                        position: absolute;
                        bottom: -24px;
                        left: 5px;
                    }
                }
            }
        }

        .navbar-toggler {
            border: none;
            padding: 0.475rem 0;
            margin-right: 0.75rem;
            cursor: pointer;

            &:focus {
                outline: none !important;
                border: none;
                box-shadow: none;
                width: 2em;
                height: 2em;
            }
        }

        .navigation {
            .sidenav {
                position: fixed;
                left: -300px;
                width: 300px;
                top: 80px;
                height: calc(100% - 80px);
                -webkit-transition: left 0.4s;
                transition: left 0.4s;
                background-color: var(--light);
                z-index: 999;

                .nav-head {
                    position: relative;
                    padding: 1em 1em 1em 85px;

                    span {
                        &.small {
                            display: block;
                            font-size: 16px;
                            font-style: italic;
                            color: grey;
                            margin-top: 5px;
                        }
                    }
                }

                .sidenav-menu {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    .sidenav-item {
                        position: relative;

                        .dropdown {
                            list-style: none;
                            padding: 0;
                            overflow: hidden;
                            display: none;

                            .menu-items {
                                padding: 0;
                                position: relative;

                                &:first-child {
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        border-top: 1px solid var(--main);
                                        top: 0;
                                        width: 100%;
                                        left: 0;
                                        opacity: 0.1;
                                    }
                                }

                                &::before {
                                    content: "";
                                    position: absolute;
                                    border-bottom: 1px dashed;
                                    top: 16px;
                                    width: 10px;
                                    left: 21px;
                                }

                                .sidenav-link {
                                    position: relative;
                                    padding: 8px 8px 8px 35px;

                                    &::after {
                                        content: "";
                                        position: absolute;
                                        left: 20px;
                                        width: 1px;
                                        height: 100%;
                                        border-left: 1px dashed;
                                        top: -18px;
                                    }
                                }
                            }
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            border-top: 1px solid var(--main);
                            top: 0;
                            width: 100%;
                            left: 0;
                            opacity: 0.3;
                        }

                        &:last-child {
                            &::after {
                                content: "";
                                position: absolute;
                                border-top: 1px solid var(--main);
                                top: 100%;
                                width: 100%;
                                left: 0;
                                opacity: 0.3;
                            }
                        }
                    }

                    .sidenav-link {
                        display: block;
                        color: var(--dark);
                        text-decoration: none;
                        position: relative;
                        font-size: 12px;

                        &::before {
                            content: "";
                            background-color: var(--main);
                            width: 0%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            opacity: 0.1;
                            -webkit-transition: width 0.4s;
                            transition: width 0.4s;
                        }

                        &.has-subnav {
                            position: relative;

                            &::after {
                                content: '\25BC';
                                position: absolute;
                                right: 15px;
                                top: 15px;
                                font-size: 15px;
                                color: var(--main);
                                opacity: 0.8;
                            }

                            &.shown,
                            &.active {
                                &::after {
                                    content: '\25B2';
                                }
                            }
                        }

                        &:hover,
                        &.active {
                            color: var(--main);

                            &::before {
                                width: 100%;
                            }
                        }



                        &.shown+.dropdown,
                        &.active+.dropdown {
                            display: block;
                        }
                    }
                }
            }

            &.toggled {
                .sidenav {
                    left: 0;
                }
            }

            img {
                &.user {
                    position: absolute;
                    left: 1em;
                    top: 1.2em;
                }
            }
        }

        .dropdown-menu {
            border-radius: 0;
            background-color: var(--light);

            li {
                a {

                    &:hover,
                    &.active {
                        background-color: var(--dark);
                        color: var(--light);
                    }
                }
            }
        }

        .notify {
            .dropdown-menu {
                &.text-small {
                    max-height: 420px;
                    width: 350px;
                }

                li {
                    margin-bottom: 2px;

                    a {
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            width: 0;
                            border-left: 7.5px solid var(--main);
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            width: 100%;
                            background-color: var(--main);
                            opacity: 0.2;
                        }
                    }
                }
            }

            .moment {
                font-size: 12px;
                line-height: 24px;
                opacity: 0.8;
            }

            p {
                margin-bottom: 0px;
            }
        }
    }

    main {
        padding: 0;

        .breadcrumb {
            background-color: var(--light);
            padding: 10px 0;

            ul {
                list-style: none;
                padding: 0;
                display: inline-flex;
                margin: 0;

                li {
                    margin-right: 5px;
                    position: relative;
                    font-size: 14px;
                    text-transform: capitalize;

                    padding-left: 12px;

                    &::before {
                        content: '>';
                        position: absolute;
                        left: 0;
                    }

                    &:first-child {
                        padding-left: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        .page-title {
            font-size: 40px;
            font-weight: 300;
            margin-bottom: 30px;
        }
    }

    footer {
        position: absolute;
        width: 100%;
        top: calc(100% - 5px);
        background: var(--light);
        line-height: 1;
        padding: 14px 0;

        p {
            margin: 0;
            font-size: 12px;
            color: #777;
        }
    }
}

.theme-scroll {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--light-grey);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--main);
    }
}

.content {
    .dataTables_wrapper {
        table {
            width: 100%;

            th {
                &.type {
                    width: 180px;
                }

                &.price,
                &.rate {
                    width: 140px;
                }

                &.quantity {
                    width: 60px;
                }

                &.item,
                &.action {
                    width: 200px;
                }
            }

            td {
                &.type {
                    text-transform: capitalize;
                }

                &.price,
                &.rate {
                    position: relative;
                    padding-left: 25px;

                    .inr {
                        position: absolute;
                        top: 8px;
                        left: 13px;

                    }
                }

                select,
                input {
                    width: 100%;
                    height: 35px;
                    padding: 0 5px;
                    background-color: var(--light);
                }
            }

            tbody {
                tr {
                    &.form {
                        background: #cdf;

                        td {

                            &.price,
                            &.rate {
                                .inr {
                                    top: 8px;
                                    background-color: var(--main);
                                    color: var(--light);
                                    left: 6px;
                                    padding: 5px 5px 6px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    table {
        &.bill-data {
            &.pull-right {
                float: right;
            }

            max-width: 450px;
            width: 100%;
            border: 1px solid var(--main);

            th {
                background-color: var(--main);
                color: var(--light);
                width: 45%;
                line-height: 30px;
            }

            td {
                line-height: 30px;

                select {
                    width: 100%;
                    height: 30px;
                    padding: 0 5px;
                }
            }
        }
    }

    .content-loader {
        width: 100%;
        text-align: center;
        position: relative;

        .lds-ellipsis {
            position: absolute;
            left: calc(50% - 40px);
        }
    }

    .table-content {
        position: relative;

        .add-link {
            position: absolute;
            top: 10px;
        }

        div[data-test="datatable-pagination"] {
            display: flex;
            flex-direction: row-reverse;
        }

        .dataTables_wrapper {
            &.disable {
                pointer-events: none;
                opacity: 0.7;
                filter: grayscale(1);
            }

            .mdb-datatable-filter {
                margin-bottom: 5px;
            }

            /*.dataTables_paginate,*/
            .dataTables_length {
                display: none;
            }

            table {
                width: 100%;
                border: 1px solid var(--main);
                box-shadow: 0 0 10px var(--main);

                thead {
                    border: 1px solid var(--main);
                    background-color: var(--main);
                    color: var(--light);
                }
            }
        }

        .dataTables_paginate {
            margin-top: -10px;

            ul {
                &.pagination {
                    --bs-pagination-padding-x: 0.75rem;
                    --bs-pagination-padding-y: 0.375rem;
                    --bs-pagination-font-size: 1rem;
                    --bs-pagination-color: var(--main);
                    --bs-pagination-bg: var(--light);
                    --bs-pagination-border-width: 1px;
                    --bs-pagination-border-color: var(--light-grey);
                    --bs-pagination-border-radius: 0.375rem;
                    --bs-pagination-hover-color: var(--light);
                    --bs-pagination-hover-bg: var(--main);
                    --bs-pagination-hover-border-color: var(--main);
                    --bs-pagination-focus-color: var(--bs-link-hover-color);
                    --bs-pagination-focus-bg: var(--main);
                    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem var(--main);
                    --bs-pagination-active-color: var(--light);
                    --bs-pagination-active-bg: var(--main);
                    --bs-pagination-active-border-color: var(--main);
                    --bs-pagination-disabled-color: #6c757d;
                    --bs-pagination-disabled-bg: var(--light);
                    --bs-pagination-disabled-border-color: var(--light-grey);

                    li {
                        &.page-item {
                            .page-link {
                                border-radius: 0 !important;
                            }

                            &:not(.disabled) {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}