:root {
  --main: #19181a;
  --light: #f1f1f1;
  --dark: #1a150a;
  --light-grey: #cfcfcf;
  --red: #aa2823;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-theme-main {
  background-color: var(--dark);
}

.lds-ellipsis {
  div {
    position: absolute;
    top: 16px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--red);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }

  &.btn-loader {
    width: 150px;
    background-color: var(--light);
    color: var(--red);
    border-color: var(--red);
    margin: 0 auto;
    border: 1px solid;
    height: 48px;
    position: relative;

    div {
      &:nth-child(1) {
        left: 40px;
      }

      &:nth-child(2) {
        left: 40px;
      }

      &:nth-child(3) {
        left: 65px;
      }

      &:nth-child(4) {
        left: 90px;
      }
    }
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

body {
  form {

    input,
    select {

      &[type="text"],
      &[type="email"],
      &[type="number"],
      &[type="password"] {
        border: 1px solid var(--main);
        border-radius: 0;
        color: var(--main);
        outline: none;
        box-shadow: 0 0 0;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 10px var(--red);
      }

      &.form-control {
        &:focus {
          outline: none;
          box-shadow: 0 0 10px var(--red);
        }
      }
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 3px;
      padding-right: 2rem;
      cursor: pointer;
    }

    .actions {
      text-align: center;
    }

    button {
      border-radius: 0 !important;

      &.btn-primary,
      &[type="submit"] {
        border-radius: 0;
        background-color: var(--red);
        border-color: var(--light);
        box-shadow: 0 0 2px var(--red);

        &:hover {
          background-color: var(--light);
          color: var(--red);
          border-color: var(--red);
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 10px var(--red);
        }
      }
    }

    .with-search {
      position: relative;

      button {
        position: absolute;
        right: 12px;
        bottom: 0;
        height: 38px;
      }
    }
  }

  .p-404 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    text-align: center;

    h1 {
      font-size: 15rem;
    }

    h2 {
      font-size: 5rem;
    }
  }
}